import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function TermCondition(props) {
    const { t,i18n } = useTranslation();
    const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { ...rest } = props;
  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
      <div className={classes.container}>
            <br/>
            <h2  style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "30px",minHeight: "32px",color: "#383838",textDecoration: "none"}}>
              Terms and Conditions - CABBYTRIP by Cinque Terre Riviera snc
            </h2>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
              <b>1. Introduction</b><br/>
              All services provided by Cinque Terre Riviera snc by Paola Rossi and Miriana Rovaron (hereinafter Cinque Terre Riviera snc for short) under the CABBYTRIP brand are governed by these "Terms and Conditions of Service".<br/>
              Through the Cabbytrip app and website, the Cinque Terre Riviera snc company allows customers to request services from third-party suppliers. Such services are provided or made available under the Cabbytrip brand.<br/>
              Third party providers, transport service providers, car rental service, are independent third parties.<br/><br/>

              <b>2. General Info</b><br/>
              Cinque Terre Riviera snc offers a technology platform that allows users of the Cabbytrip app and website to request transport services provided by independent transport service providers (“Transport Service Providers”).<br/><br/>

              <b>2a. Platform</b><br/>
              Cinque Terre Riviera snc provides the technological platform that allows users seeking transport services to get in touch with transport service providers available to offer the requested service. Cinque Terre Riviera snc is not a transport service provider or a carrier. All transport services obtained through the Cabbytrip app and website are offered by transport service providers.<br/>
              Cabbytrip / Cinque Terre Riviera snc provides access to the Cabbytrip platform, which allows users seeking transport services to get in touch with transport service providers available to offer the requested service. The matching service between users and transport service providers via the Cabbytrip platform is free.<br/>
              Cabbytrip does not provide, purchase or resell transport services. These services are offered directly by the transport service providers. By sending a ride request via the Cabbytrip app or website, the passenger agrees to enter into a transport contract directly with the transport service provider, of whom Cabbytrip is not a contracting party.<br/><br/>

              <b>2.b Transport service providers</b><br/>
              All transport service providers who offer their services via the Cabbytrip app and website are independent professionals who have a contractual relationship with Cabbytrip / Cinque Terre Riviera snc. To register and provide transport services via the Cabbytrip app and website (to the extent Cabbytrip is available in their city/region), transport service providers must meet the legal and regulatory conditions governing access to their profession. In accordance with its legal obligations, Cinque Terre Riviera snc verifies documents and information of transport service providers before allowing their access to the Cabbytrip app and website.<br/>
              In case of failure to comply with their legal or contractual obligations, transport service providers may lose the right to access the Cabbytrip platform and Cinque Terre Riviera snc reserves the right to terminate the existing contract with them. The reviews and any complaints from passengers will be evaluated in order to consider the services offered by each service provider.<br/>
              All users of the Cabbytrip platform (passengers and transport service providers) agree to comply with all rules regarding safety, quality or compliance established by law in the “Codice della Strada” and the Italian Civil and Penal Code. Failure to comply may result in loss of access to the Cabbytrip platform. This values both for providers and passengers.<br/>
              Cabbytrip / Cinque Terre Riviera snc and the transport service providers may exercise their respective right to withdraw from the contract that binds them by giving notice, with the consequent exclusion of the transport service provider.<br/>
              Transport service providers using the Cabbytrip platform are not bound by any exclusivity obligations. They will always be able to exercise their right to accept or refuse a ride request.<br/><br/>

              <b>2.c Requests for rides from transport service providers</b><br/>
              When a ride request is submitted via the Cabbytrip app or website; this will be forwarded to a transport service provider that is available or may be available soon. The platform will select a transport service provider with the aim of minimizing waiting times for all passengers who are in the same geographical area. The transport service provider will be free to accept or decline the ride request. If the transport service provider has accepted the ride request, they may cancel it only and exclusively upon payment of a cancellation fee.<br/>
              When you request a ride from an airport, your request is distributed among driver partners according to an electronic waitlist system that works on a first-come, first-served basis.<br/>
              Cabbytrip may use certain parameters in the Cabbytrip app and website to determine the display order of the different mobility offers available. These parameters are selected exclusively to optimize the user experience. Key positioning parameters include: mobility options previously chosen by the user, type of device, popularity of products based on general demand, average arrival times for each available option, contextual factors (e.g. time of day and day of the week) and availability of transport service providers. These parameters are subject to change at any time.<br/><br/>
              
              <b>2.d Commission on services</b><br/>
              To access the Cabbytrip platform, transport service providers agree to pay Cabbytrip / Cinque Terre Riviera snc a service fee as a percentage of the ride price after the provision of their services.<br/><br/>

              <b>2.e Method of payment for the service by the passenger</b><br/>
              In Cabbytrip app and website, the passenger will be able to select the preferred payment method among those allowed by the platform for the transport services requested and performed. Depending on the city where the ride will take place, it may also be possible to pay the transport provider directly in cash.<br/>
              Digital payments are collected and processed by Cabbytrip / Cinque Terre Riviera snc in the name and on behalf of the transport service provider. These payment services are provided by Cabbytrip / Cinque Terre Riviera snc to the transport service provider and allow the latter to receive the payments due within the contractual relationship stipulated net of the commissions that will be withheld including the expected VAT percentage by current legislation.<br/><br/>

              <b>2.f Disputes</b><br/>
              Cinque Terre Riviera snc provides the platform for transport services on behalf of transport service providers via the Cabbytrip app or website. While providing assistance to customers, the contract for transport services is stipulated directly between the passenger and the transport service provider. Any dispute regarding the transport service will be resolved directly between the passenger and the transport service provider.<br/>
              Cabbytrip service (i.e. matching of users and transport service providers via the Cabbytrip platform), Cabbytrip can provide initial assistance to users acting as consumers for the amicable resolution of disputes relating to the Cabbytrip service.<br/>
              Any further action will have to be between the passenger (customer) and the service provider.<br/>
              In accordance with Italian law, for any dispute of a contractual nature relating to the Cabbytrip service that cannot be resolved in the context of a complaint previously submitted to Cabbytrip customer support, consumers will be able to make use of the Arbitration service of the <a href="http://www.rivlig.camcom.gov.it/IT/Page/t03/view_html?idp=1544">Riviere di Liguria Chamber of Commerce</a><br/>
              For information <a href="mailto:conciliazione.sp@rivlig.camcom.it">conciliazione.sp@rivlig.camcom.it</a> for the La Spezia office.<br/>
              Eligible consumers may also submit complaints regarding the Cabbytrip service to the European Commission's online dispute resolution platform accessible here, pursuant to Regulation (EU) No. 524/2013 of 21 May 2013. Cabbytrip does not undertake to join mediation schemes or use an online dispute resolution platform to resolve consumer disputes.<br/>
              The provision of the Cabbytrip service (i.e. the matching of users and transport service providers via the Cabbytrip platform) will be governed by and construed exclusively in accordance with the laws of the Netherlands, excluding its conflict of law provisions.<br/>
              However, if you reside in the EU, the consumer protection laws that are most beneficial to you in your country of residence may apply.<br/><br/>

              <b>3. Evaluations</b><br/>
              Cabbytrip collects review ratings from both service providers and passengers but does not display individual ratings associated with a specific ride. The transport service provider and the user cannot contact the author of a particular review and express their disagreement. Ratings and reviews for a user or Transport Service Provider can only be left by the registered account providing or using the Transport Service.<br/><br/>

              <b>3.a Ratings submitted by the passenger</b><br/>
              At the end of each service, all the users will have the right to rate their transport service provider by assigning from 1 to 5 stars based on their experience during the ride. Individual ratings will not be visible in the Cabbytrip app or website, rather you will only see an average rating based on rides taken.<br/>
              Passengers will not receive any compensation in exchange for submitting reviews about their experience.<br/><br/>

              <b>3.b Ratings submitted by the service provider</b><br/>
              At the end of each service, transport service providers will have the right to rate their passenger(s) by assigning 1 to 5 stars based on the experience during the ride. Individual ratings will not be visible in the Cabbytrip app or website, rather you will only see an average rating based on rides taken.<br/><br/>

              <b>4. Online acceptance of the conditions of use</b><br/>
              Use of the site is subject to acceptance of these Terms & Conditions. When creating a User Account, Users must select the "I accept the General Terms & Conditions of Use of the site and the proposed service" box.<br/>
              Only acceptance of the Terms & Conditions allows users to access the services offered by the Site. Acceptance of these conditions is integral and indivisible: therefore users cannot choose to apply only some of the conditions or to reserve on them. By accepting the General Conditions of Use, the User also accepts the "Processing of Users' Personal Data" pursuant to the privacy policy.<br/>
              For anything not expressly indicated, reference is made to all legal provisions established by Italian law.<br/><br/>

              <b>5. Jurisdiction</b><br/>
              For any dispute relating to the relationship between the parties, the Court of La Spezia will have exclusive jurisdiction.<br/>
              Cinque Terre Riviera and Cabbytrip are registered trademarks.<br/><br/>
            </p>

            <h2  style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "20px",minHeight: "32px",color: "#383838",textDecoration: "none"}}>
              Termini & Condizioni - CABBYTRIP di Cinque Terre Riviera snc
            </h2>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
              <b>1. Introduzione</b><br/>
              I servizi forniti da Cinque Terre Riviera snc di Paola Rossi e Miriana Rovaron (in breve di seguito Cinque Terre Riviera snc) con il marchio CABBYTRIP sono regolati dai presenti “Termini e Condizioni di Servizio”.<br/>
              Tramite la app e il sito web Cabbytrip la società Cinque Terre Riviera snc permette di richiedere servizi a fornitori di terze parti. Tali servizi sono forniti o resi disponibili con il marchio Cabbytrip.<br/>
              I fornitori di terze parti, fornitori di servizi di trasporto, servizio di noleggio auto, sono soggetti terzi indipendenti.<br/><br/>

              <b>2. Generali</b><br/>
              Cinque Terre Riviera snc offre una piattaforma tecnologica che consente agli utenti dell'app e del sito web Cabbytrip di richiedere servizi di trasporto forniti da fornitori di servizi di trasporto indipendenti ("Fornitori di servizi di trasporto").<br/><br/>

              <b>2a. Piattaforma</b><br/>
              Cinque Terre Riviera snc mette a disposizione la piattaforma tecnologica che consente agli utenti che cercano servizi di trasporto di entrare in contatto con i fornitori di servizi di trasporto disponibili a offrire il servizio richiesto. Cinque Terre Riviera snc non è un fornitore di servizi di trasporto o un vettore. Tutti i servizi di trasporto ottenuti tramite l'app e il sito web Cabbytrip sono offerti da fornitori di servizi di trasporto.<br/>
              Cabbytrip / Cinque Terre Riviera snc fornisce accesso alla piattaforma Cabbytrip, che consente agli utenti che cercano servizi di trasporto di entrare in contatto con i fornitori di servizi di trasporto disponibili a offrire il servizio richiesto. Il servizio di abbinamento tra utenti e Fornitori di servizi di trasporto tramite la piattaforma Cabbytrip è gratuito.<br/>
              Cabbytrip non fornisce, acquista o rivende servizi di trasporto. Tali servizi sono offerti direttamente dai fornitori di servizi di trasporto. Il passeggero, inviando una richiesta di corsa tramite l'app o il sito web Cabbytrip, accetta di stipulare un contratto di trasporto direttamente con il fornitore di servizi di trasporto, di cui Cabbytrip non costituisce parte contraente.<br/><br/>

              <b>2.b Fornitori di servizi di trasporto</b><br/>
              I fornitori di servizi di trasporto che offrono i loro servizi tramite l'app e il sito web Cabbytrip sono professionisti autonomi che hanno un rapporto contrattuale con Cabbytrip / Cinque Terre Riviera snc. Per registrarsi e fornire servizi di trasporto tramite l'app e il sito web Cabbytrip (nella misura in cui Cabbytrip sia disponibile nella loro città/area geografica), i fornitori di servizi di trasporto devono soddisfare le condizioni legali e normative che disciplinano l'accesso alla loro professione. Conformemente ai suoi obblighi legali, Cinque Terre Riviera snc verifica documenti e informazioni dei fornitori di servizi di trasporto prima di consentire il loro accesso all'app e al sito web Cabbytrip.<br/>
              In caso di mancato rispetto dei loro obblighi legali o contrattuali, i fornitori di servizi di trasporto possono perdere il diritto di accesso alla piattaforma Cabbytrip e Cinque Terre Riviera snc si riserva il diritto di chiudere il contratto in essere con loro. Le recensioni ed eventuali reclami dei passeggeri saranno valutati al fine della considerazione deli servizi offerti da ogni fornitore di servizi.<br/>
              Tutti gli utenti della piattaforma Cabbytrip (passeggeri e fornitori di servizi di trasporto) accettano di rispettare, tutte le regole in materia di sicurezza, qualità o conformità previste per legge dal Codice della Strada e dal Codice Civile e Penale Italiano. La mancata osservanza potrà comportare la perdita dell'accesso alla piattaforma Cabbytrip.<br/>
              Cabbytrip / Cinque Terre Riviera snc e i fornitori di servizi di trasporto potranno esercitare il rispettivo diritto di recedere dal contratto che li vincola dandone preavviso, con la conseguente esclusione del fornitore di servizi di trasporto.<br/>
              I fornitori di servizi di trasporto che utilizzano la piattaforma Cabbytrip non sono vincolati da alcun obbligo di esclusività. Potranno esercitare sempre il loro diritto di accettare o rifiutare una richiesta di corsa.<br/><br/>

              <b>2.c Richieste di corse ai fornitori di servizi di trasporto</b><br/>
              Quando viene inviata una richiesta di corsa tramite l'app o il sito web Cabbytrip; questa verrà inoltrata ad un fornitore di servizi di trasporto disponibile o che potrebbe esserlo a breve. La piattaforma selezionerà un fornitore di servizi di trasporto con l'obiettivo di ridurre al minimo i tempi di attesa per tutti i passeggeri che si trovano nella stessa area geografica. Il fornitore di servizi di trasporto sarà libero di accettare o rifiutare la richiesta di corsa. che Se il fornitore di servizi di trasporto ha accettato la richiesta di corsa, potrà ritirarla solamente ed esclusivamente dietro pagamento di un costo di cancellazione.<br/>
              Quando si richiede una corsa da un aeroporto, la richiesta viene distribuita tra gli autisti partner secondo un sistema di lista di attesa elettronico che funziona in base all'ordine di arrivo delle richieste.<br/>
              Cabbytrip può utilizzare determinati parametri nell'app e sul sito web Cabbytrip per stabilire l'ordine di visualizzazione delle diverse offerte di mobilità disponibili. Tali parametri vengono selezionati esclusivamente per ottimizzare l'esperienza dell'utente. I principali parametri di posizionamento includono: opzioni di mobilità scelte in precedenza dall'utente, tipo di dispositivo, popolarità dei prodotti in base alla domanda generale, tempi di arrivo medi per ciascuna opzione disponibile, fattori contestuali (ad es. ora del giorno e giorno della settimana) e disponibilità dei fornitori di servizi di trasporto. Questi parametri sono soggetti a modifiche in qualsiasi momento.<br/><br/>
              
              <b>2.d Commissione su servizi</b><br/>
              Per accedere alla piattaforma Cabbytrip, i fornitori di servizi di trasporto accettano di pagare a Cabbytrip / Cinque Terre Riviera snc un costo del servizio in percentuale sul prezzo della corsa dopo la prestazione dei propri servizi.<br/><br/>

              <b>2.e Modalità dei pagamento del servizio da parte del passeggero</b><br/>
              Nell'app e sito web Cabbytrip il passeggero potrà selezionare la modalità di pagamento preferita tra quelle ammesse dalla piattaforma per i servizi di trasporto richiesti ed effettuati. A seconda della città in cui la corsa verrà effettuata, potrebbe anche essere possibile pagare il fornitore di servizi di trasporto direttamente in contanti.<br/>
              I pagamenti digitali sono riscossi ed elaborati da Cabbytrip / Cinque Terre Riviera snc a nome e per conto del fornitore di servizi di trasporto. Tali servizi relativi al pagamento sono forniti da Cabbytrip / Cinque Terre Riviera snc al fornitore di servizi di trasporto e consentono a quest'ultimo di ricevere i pagamenti dovuti nell'ambito del rapporto contrattuale stipulato al netto delle commissioni che verranno trattenute inclusive dell percentuale IVA prevista dalla normativa vigente.<br/><br/>

              <b>2.f Contestazioni</b><br/>
              Cinque Terre Riviera snc fornisce la piattaforma per i servizi di trasporto per conto dei fornitori di servizio di trasporto tramite l'app o il sito web Cabbytrip. Pur fornendo assistenza ai clienti, il contratto per le prestazioni di trasporto è stipulato direttamente tra il il passeggero e il fornitore di servizi di trasporto. Qualsiasi controversia relativa al servizio di trasporto sarà risolta direttamente tra il passeggero e il fornitore di servizi di trasporto.<br/>
              Il servizio Cabbytrip (ossia abbinamento di utenti e fornitori di servizi di trasporto tramite la piattaforma Cabbytrip), Cabbytrip può fornire assistenza iniziale agli utenti che agiscono in qualità di consumatori per la risoluzione amichevole delle controversie relative al servizio Cabbytrip. Qualsiasi ulteriore azione dovrà avvenire tra il passeggero (cliente) e il fornitore del servizio.<br/>
              In conformità alla legge italiana, per qualsiasi controversia di natura contrattuale relativa al servizio Cabbytrip che non possa essere risolta nel contesto di un reclamo precedentemente presentato all'assistenza clienti Cabbytrip, i consumatori potranno avvalersi del servizio di Arbitrato della Camera di Commercio <a href="http://www.rivlig.camcom.gov.it/IT/Page/t03/view_html?idp=1544">Riviere di Liguria </a> 
              Per informazioni <a href="mailto:conciliazione.sp@rivlig.camcom.it">conciliazione.sp@rivlig.camcom.it</a> per la sede della Spezia.<br/>
              I consumatori idonei possono inoltre inviare reclami relativi al servizio Cabbytrip alla piattaforma di risoluzione delle controversie online della Commissione europea accessibile qui, ai sensi del Regolamento (UE) n. 524/2013 del 21 maggio 2013. Cabbytrip non si impegna ad aderire a schemi di mediazione o a utilizzare una piattaforma di risoluzione delle controversie online per risolvere le controversie dei consumatori.<br/>
              L'erogazione del servizio Cabbytrip (ovvero l'abbinamento di utenti e fornitori di servizi di trasporto tramite la piattaforma Cabbytrip) sarà disciplinata e interpretata esclusivamente in conformità alle leggi dei Paesi Bassi, a esclusione delle norme relative ai conflitti di leggi. Tuttavia, se risiedi nell'UE, potrebbero applicarsi le disposizioni di legge in materia di tutela dei consumatori più vantaggiose per il passeggero nel proprio paese di residenza.<br/><br/>

              <b>3. Valutazioni</b><br/>
              Cabbytrip raccoglie le valutazioni recensioni sia dei fornitori di servizi sia dei passeggeri ma non mostra le singole valutazioni associate a una corsa specifica. Il fornitore di servizi di trasporto e l'utente non possono contattare l'autore di una determinata recensione ed esprimere il proprio dissenso. Le valutazioni e le recensioni per un utente o Fornitore di servizi di trasporto possono essere lasciate solo dall'account registrato che eroga o utilizza il servizio di trasporto.<br/><br/>

              <b>3.a Valutazioni inviate dal passeggero</b><br/>
              Al termine di ogni servizio gli utenti avranno la facoltà di valutare il proprio fornitore di servizi di trasporto assegnando da 1 a 5 stelle in base all'esperienza durante la corsa. Le valutazioni individuali non saranno visibili nell'app o sul sito web Cabbytrip, bensì sarà visibile solo una valutazione media in base alle corse effettuate.<br/>
              I passeggeri non riceveranno alcun corrispettivo in cambio dell'invio di recensioni sulla loro esperienza.<br/><br/>

              <b>3.b Valutazioni inviate dal fornitore di servizi</b><br/>
              Al termine di ogni servizio i fornitori di servizi di trasporto avranno la facoltà di valutare il/i proprio/i passeggero/i assegnando da 1 a 5 stelle in base all'esperienza durante la corsa. Le valutazioni individuali non saranno visibili nell'app o sul sito web Cabbytrip, bensì sarà visibile solo una valutazione media in base alle corse effettuate.<br/><br/>

              <b>4. Accettazione online delle condizioni d'uso</b><br/>
              L'utilizzo del sito è subordinato all'accettazione dei presenti Termini & Condizioni. Quando si crea un Account Utente, gli Utenti devono selezionare la casella "Accetto Termini & Condizioni Generali di Utilizzo del sito e del servizio proposto".<br/>
              Solo l'accettazione di Termini & Condizioni consente agli utenti di accedere ai servizi offerti dal Sito. L'accettazione delle presenti condizioni è integrale ed indivisibile: pertanto gli utenti non possono scegliere di applicare solo alcune delle condizioni o di riservare su di esse. Accettando le Condizioni Generali d'Uso, l'Utente accetta anche il "Trattamento dei Dati Personali degli Utenti" ai sensi dell'informativa sulla privacy.<br/>
              Per tutto quanto non espressamente indicato si fa rinvio e fanno fede tutte le disposizioni di legge previste dalla Legge Italiana.<br/><br/>

              <b>5. Giurisdizione</b><br/>
              Per ogni controversia relativa al rapporto tra le parti sarà competente in via esclusiva il Foro di La Spezia.<br/>
              Cinque Terre Riviera e Cabbytrip sono marchi registrati.<br/><br/>
            </p>
          <br/>
          <br/>
        </div>
        </div>
      <Footer/>
    </div>
  );
}
