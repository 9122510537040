import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import ListItem from "@mui/material/ListItem";
import IconButton from '@mui/material/IconButton';
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function ContactUs(props) {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const classes = useStyles();
    const { ...rest } = props;
    const settings = useSelector(state => state.settingsdata.settings);
    return (
        <div style={{margin:'-8px'}}>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/header-back.jpg").default} />
            <div className={classNames(classes.main, classes.mainRaised)}>

                <div className={classes.container}>
                    <br />
                    <h2 style={{ textAlign:isRTL === 'rtl'?'right':'left',position: "relative", marginTop: "30px", minHeight: "32px", color: "#383838", textDecoration: "none" ,[isRTL === "rtl" ? "marginRight" : "marginRight"]: isRTL ? "30px" : "0px",wordBreak: "break-word"}}>{t('contact_us')}</h2>
                    <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{  color: 'black', fontSize: 16 }}> How to reach us!</p>
                    {
                        settings && settings.contact_email 
                        ? <p><span className={classes.description}>{t('email')}: </span>
                            <a href={"mailto:" + settings.contact_email}  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>{settings.contact_email}</a>
                        </p>
                        : null
                    }

                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', color: 'black', fontSize: 16 }}>
                        Vernazza office:<br/>
                        Via Roma, 24 – Vernazza – 19018 – SP – Italy Ph. +39 (0)3517858879
                    </p>
                    
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2863.51270533777!2d9.678892555545382!3d44.13467234683539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12d4f117a7e77bcd%3A0x9c36d7b40121d864!2sVia%20Roma%2C%2024%2C%2019018%20Vernazza%20SP%2C%20Italy!5e0!3m2!1sen!2sin!4v1698488572937!5m2!1sen!2sin"
                        width="1000"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                    ></iframe>
                    
                    {
                        settings && settings.contact_email 
                        ? <p>
                            <span className={classes.description}>Please, contact us only via email </span>
                            <a href={"mailto:" + settings.contact_email} className={classes.description}>{settings.contact_email}</a>
                        </p>
                        : null
                    }
                    <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ display: 'none', color: 'black', marginTop: 10 }}>Follow Us</p>
                    
                    <div dir={isRTL === "rtl" ? "rtl" : "ltr"} >
                        <div  style={{ display: 'none', width: 40, marginTop: -15,[isRTL === "rtl" ? "marginRight" : "marginLeft"]: isRTL === "rtl"  ? "10px" : "-23px"}}>
                        {settings && settings.FacebookHandle ?
                            <ListItem className={classes.listItem}>
                                <Tooltip
                                    id="instagram-facebook"
                                    title={t('follow_facebook')}
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton 
                                        href={settings.FacebookHandle}
                                        target="_blank"
                                        className={classes.navLink}
                                        style={{color:'#3b5998'}}
                                    >
                                        <i className={classes.socialIcons + " fab fa-facebook"}/>
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                            : null}
                        {settings && settings.TwitterHandle ?
                            <ListItem className={classes.listItem}>
                                <Tooltip
                                    id="instagram-twitter"
                                    title={t('follow_twitter')}
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        href={settings.TwitterHandle}
                                        target="_blank"
                                        className={classes.navLink}
                                        style={{color:'#00acee'}}
                                    >
                                        <i className={classes.socialIcons + " fab fa-twitter"} />
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                            : null}
                             {settings && settings.InstagramHandle ?
                            <ListItem className={classes.listItem}>
                                <Tooltip
                                    id="instagram-twitter"
                                    title={t('follow_instagram')}
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        href={settings.InstagramHandle}
                                        target="_blank"
                                        className={classes.navLink}
                                        style={{color:'#833AB4'}}
                                    >
                                        <i className={classes.socialIcons + " fab fa-instagram"} />
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        : null }
                    </div>
                    </div>
                    <br />
                </div>
            </div>

            <Footer />
        </div>
    );
}