export const FirebaseConfig = {
	"projectId": "cabbytrip-44bdb",
	"appId": "1:397367769021:web:2bc56e7bd38f40deb7c4d4",
	"databaseURL": "https://cabbytrip-44bdb-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "cabbytrip-44bdb.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBSCB_JLmjmyj6HL_UJ9cidEb2PrIgnOyY",
	"authDomain": "cabbytrip-44bdb.firebaseapp.com",
	"messagingSenderId": "397367769021",
	"measurementId": "G-2NE3EDNS5E"
};