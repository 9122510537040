import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function PrivacyPolicy(props) {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const classes = useStyles();
    const settings = useSelector(state => state.settingsdata.settings);
    const { ...rest } = props;
    return (
        <div style={{ margin: '-8px' }}>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/header-back.jpg").default} />
            <div className={classNames(classes.main, classes.mainRaised)}>

                <div className={classes.container}>
                    <br />
                    <h2 style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', position: "relative", marginTop: "30px", minHeight: "32px", color: "#383838", textDecoration: "none" }}>
                        PRIVACY ACCEPTANCE - ENGLISH
                    </h2>
                    
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        Pursuant to EU Regulation no. 2016/679 (hereinafter, “GDPR”). We provide below the information regarding the processing of your personal data provided by you to Cinque Terre Riviera snc in relation to the research and selection of personnel.<br/><br/>

                        <b>1. Purpose of the processing for which the data are intended</b><br/>
                        The data you provide are exclusively those necessary for the use of the tourist services offered by Cinque Terre Riviera snc di Paola Rossi e Miriana Rovaron and all the information connected to them.<br/><br/>
                        
                        <b>2. Methods of data processing</b><br/>
                        In relation to the aforementioned purposes, personal data are processed on paper and through IT / telematic tools, in order to guarantee the security and confidentiality of the data in compliance with the regulations in force. The data will be kept for the duration of the selection procedure and for the possible establishment of an employment relationship. It should also be noted that the aforementioned data (in particular those relating to personal requirements and professional career) may be subject to verification.<br/><br/>

                        <b>3. Obligation or optional nature of providing data</b><br/>
                        The provision of data is optional but any refusal to provide such data could result in the failure to continue the selection process.<br/><br/>

                        <b>4. Communication and knowledge of data</b><br/>
                        The personal data you provide may be disclosed to competent authorities for legal obligations or to individuals to fulfill contractual obligations, or to consultants (both natural and legal persons) to whom Cinque Terre Riviera snc di Paola Rossi e Miriana Rovaron should entrust tasks for the performance of certain activities. relating to the operation of the website. The subjects indicated above, where necessary, will be appointed as Data Processors. Your data may also be known by our employees, who are specifically authorized to process such data for the pursuit of the aforementioned purposes. Cinque Terre Riviera snc di Paola Rossi e Miriana Rovaron may also process and transmit your personal information to partner companies in Italy or abroad (EU or non-EU). These companies operate as data controllers or joint controllers. Regardless of the place where personal information is collected or processed, Cinque Terre Riviera snc adopts adequate administrative, technical and physical protections to protect the information, through the stipulation of standard contractual clauses provided by the European Commission.<br/><br/>

                        <b>5. Rights of the interested party</b><br/>
                        At any time you can exercise the rights attributed by art. 15 GDPR, where applicable, and precisely the rights of:<br/>
                        - access to personal data (including origin of personal data, purposes and methods of treatment, logic applied in case of treatment carried out with electronic tools, identification details of the owner, of the persons in charge or of the categories of persons to whom the personal data may be communicated or who may learn about it) <br/>
                        - obtain the updating, rectification or cancellation of the same or the limitation of processing; <br/>
                        - oppose the treatment; <br/>
                        - data portability; <br/>
                        - withdraw consent; <br/>
                        - propose a complaint to the supervisory authority (Privacy Guarantor).<br/><br/>

                        <b>6. Contact methods</b><br/>
                        Data controller: Cinque Terre Riviera snc di Paola Rossi e Miriana Rovaron <br/>
                        - Via Roma 24 19018 Vernazza, Italy To exercise the rights referred to in point 4, you can contact Cinque Terre Riviera snc, either by ordinary mail or by e-mail at the address: <a href="mailto:info@cinqueterreriviera.com">info@cinqueterreriviera.com</a><br/>
                    </p>

                    <h2 style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', position: "relative", marginTop: "30px", minHeight: "32px", color: "#383838", textDecoration: "none" }}>
                        ACCETTAZIONE PRIVACY - ITALIANO
                    </h2>
                    
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        Ai sensi del Regolamento UE n. 2016/679 (di seguito, “GDPR”). Di seguito forniamo le informazioni relative al trattamento dei dati personali da Lei forniti a Cinque Terre Riviera snc in relazione alla ricerca e selezione del personale.<br/><br/>

                        <b>1. Finalità del trattamento cui sono destinati</b><br/>
                        i dati I dati da Lei forniti sono esclusivamente quelli necessari per la fruizione della piattaforma via app o sito web Cabbytrip di Cinque Terre Riviera snc di Paola Rossi e Miriana Rovaron e tutte le informazioni ad essi connesse.<br/><br/>
                        
                        <b>2. Modalità del trattamento dei dati</b><br/>
                        In relazione alle finalità di cui sopra, i dati personali sono trattati su supporto cartaceo e mediante strumenti informatici/telematici, al fine di garantire la sicurezza e la riservatezza dei dati stessi nel rispetto della normativa vigente. I dati saranno conservati per tutta la durata della procedura di selezione e per l'eventuale instaurazione di un rapporto di lavoro. Si precisa, inoltre, che i predetti dati (in particolare quelli relativi ai requisiti personali e alla carriera professionale) possono essere oggetto di verifica.<br/><br/>

                        <b>3. Obbligo o facoltatività</b><br/>
                        Il conferimento dei dati Il conferimento dei dati è facoltativo ma l'eventuale rifiuto di fornire tali dati potrebbe comportare la mancata prosecuzione della procedura di selezione.<br/><br/>

                        <b>4. Comunicazione e conoscenza dei dati</b><br/>
                        I dati personali forniti potranno essere comunicati alle autorità competenti per obblighi di legge ovvero a soggetti per adempiere ad obblighi contrattuali, ovvero a consulenti (persone fisiche e giuridiche) ai quali Cinque Terre Riviera snc di Paola Rossi e Miriana Rovaron dovesse affidare incarichi per la svolgimento di determinate attività. relative al funzionamento del sito web o app. I soggetti sopra indicati, ove necessario, saranno nominati Responsabili del trattamento. I suoi dati potranno essere conosciuti anche dai nostri dipendenti, i quali sono specificamente autorizzati a trattare tali dati per il perseguimento delle finalità sopra indicate. Cinque Terre Riviera snc di Paola Rossi e Miriana Rovaron potrà inoltre trattare e trasmettere i dati personali a società partner in Italia o all'estero (UE o extra UE). Tali società operano in qualità di titolari del trattamento o contitolari del trattamento. Indipendentemente dal luogo in cui le informazioni personali sono raccolte o trattate, Cinque Terre Riviera snc adotta adeguate tutele amministrative, tecniche e fisiche per proteggere le informazioni, attraverso la stipula di clausole contrattuali tipo previste dalla Commissione Europea.<br/><br/>

                        <b>5. Diritti dell interessato In ogni momento il cliente potrà esercitare</b><br/>
                        i diritti attribuiti dall'art. 15 GDPR, ove applicabile, e precisamente i diritti di:<br/>
                        - accesso ai dati personali (tra cui origine dei dati personali, finalità e modalità del trattamento, logica applicata in caso di trattamento effettuato con strumenti elettronici, estremi identificativi del titolare, del dei responsabili o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza) <br/>
                        - ottenere l'aggiornamento, la rettificazione o la cancellazione degli stessi o la limitazione del trattamento;<br/>
                        - opporsi al trattamento; <br/>
                        - portabilità dei dati;<br/>
                        - revocare il consenso;<br/>
                        - proporre reclamo all'autorità di controllo (Garante Privacy).<br/><br/>

                        <b>6. Modalità di contatto Titolare</b><br/>
                        Del trattamento: Cinque Terre Riviera snc di Paola Rossi e Miriana Rovaron<br/>
                        - Via Roma 24 19018 Vernazza, Italia Per esercitare i diritti di cui al punto 4 potete rivolgervi a Cinque Terre Riviera snc di Paola Rossi e Miriana Rovaron, sia tramite posta ordinaria che tramite posta elettronica all'indirizzo <a href="mailto:passengers@cabbytrip.com">passengers@cabbytrip.com</a> o <a href="mailto:drivers@cabbytrip.com">drivers@cabbytrip.com</a><br/>
                    </p>
                    <br/>
                    <br/>
                </div>
            </div>
            <Footer />
        </div>
    );
}
